import { initialSubState } from '@gv/state';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import { UserActions } from '@gv/user';

import type { UserState } from '../state/user.state';
import { initialUserState } from '../state/user.state';

export const userFeatureKey = 'user';

const userReducer = createReducer(
  initialUserState,

  on(UserActions.logoutEverywhere.start, (state): UserState => {
    if (!state.loggingOutEverywhere) {
      return {
        ...state,
        loggingOutEverywhere: true,
      };
    }
    return state;
  }),

  on(UserActions.logoutEverywhere.completed, (state): UserState => {
    if (state.loggingOutEverywhere) {
      return {
        ...state,
        loggingOutEverywhere: false,
      };
    }
    return state;
  }),

  on(UserActions.logout, (state): UserState => {
    return {
      ...state,
      detail: initialSubState,
    };
  }),
);

export function reducer(
  state: UserState | undefined,
  action: Action,
): UserState {
  return userReducer(state, action);
}
