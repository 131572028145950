import type { UserCardDTO } from '@gv/api';
import type { SubStateObjectModel } from '@gv/state';
import { initialSubState } from '@gv/state';

export interface UserState {
  loggingOutEverywhere: boolean;

  detail: SubStateObjectModel<UserCardDTO & { dtSent: Date }>;
}

export const initialUserState: UserState = {
  loggingOutEverywhere: false,

  detail: initialSubState,
};
