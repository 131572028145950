import { createFeatureSelector } from '@gv/state';
import { createSelector } from '@ngrx/store';

import { userFeatureKey } from '../reducer/user.reducer';
import type { AppState } from '../state/app-state';
import type { UserState } from '../state/user.state';

export const getUserState = createFeatureSelector<AppState>(userFeatureKey);

const getLoggingOutEverywhere = createSelector(
  getUserState,
  (state: UserState) => !!state.loggingOutEverywhere,
);

export const fromUserState = {
  getUserState,
  getLoggingOutEverywhere,
};
